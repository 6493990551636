import Helmet from "react-helmet"
import React from "react"
import AMOthankyou from "@tightrope/amotyp"
import SiteData from "branding/site-metadata.json"
import Data from "./data/data.json"



export default function ThankYouAmo() {
  return(
    <section>
      <AMOthankyou siteData={SiteData} data={Data}></AMOthankyou>
    </section>
  )
}
